import React from "react";
import playStore from "../Assets/Playstore_and_App_Store_Icon/playstore.png";
import applestore from "../Assets/Playstore_and_App_Store_Icon/applestore.png";

const Content8 = () => {
  return (
    <section id="ContactUs">
      <div
        style={{
          minHeight: "100vh",
          // backgroundColor: "#FFF2EF",
          paddingTop: 45,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 30,
          // clipPath: "polygon(0 8%, 100% 0%, 100% 93%, 0% 100%)",
          padding: "0 20px", // Add padding to the sides for smaller screens
        }}
      >
        <div
          style={{
            fontSize: "clamp(20px, 4vw, 30px)", // Responsive font size
            fontWeight: 700,
            letterSpacing: 2,
            textAlign: "center", // Center text for all screens
          }}
        >
          Join Moto Doctor Community:
        </div>
        <div
          style={{
            width: "100%", // Use full width on smaller screens
            maxWidth: "800px", // Limit width for larger screens
            textAlign: "center", // Center text for all screens
          }}
        >
          Become a part of our growing community of motorcycle enthusiasts who trust MotoDoctor for their on-the-road
          needs. Whether you're a daily commuter or a weekend adventurer, we're here to ensure your journey stays smooth
          and enjoyable. Download MotoDoctor today and experience the freedom of the open road with the confidence that
          help is just a tap away!
        </div>
        <hr
          style={{
            width: "5%",
            border: "1.5px solid #FF8264",
          }}
        />
        <div
          style={{
            display: "flex",
            gap: 20,
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <a href="https://play.google.com/store/apps/details?id=com.vahaninc.autodoctor" target="_blank">
            <img src={playStore} style={{ maxWidth: "100%", height: "auto" }} alt="Play Store" />
          </a>
          <a href="https://apps.apple.com/in/app/motodoctor-rider/id6478849754" target="_blank">
            <img src={applestore} style={{ maxWidth: "100%", height: "auto" }} alt="Apple Store" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Content8;
