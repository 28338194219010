import React from "react";
import Content1 from "./content1";
import Content6 from "./content6";
import Content7 from "./content7";
import Content4 from "./content4";
import Content5 from "./content5";
import Content8 from "./content8";
// import Content9 from "./content9";
// import Content10 from "./content10";
import TestimonialSlider from "./testimonal";
// import MotoDoctorBenefits from "./benifits";
import Hero from "./hero";
// import FAQ from "./faq";
// import KeyServices from "./keyServices";
import HowItWorks from "./howItWorks";
// import QuickAccess from "./quickAccess";
// import ContactUs from "./contactUs";
// import PickupDrop from "./pickupDrop";
// import Mission from "./benifits";
// import ServiceCommitment from "./keyServices";
function Home() {
  return (
    <div id="Home">
      {/* home-page */}
      <Hero />
      <Content5 />
      <HowItWorks />
      <TestimonialSlider />
      <Content4 />
      <Content8 />
      {/* <ContactUs/> */}
      {/* {service-page} */}
      {/* <Content6 />
      <PickupDrop /> */}
      {/* about-page */}
      {/* <Mission />
      <ServiceCommitment /> */}
      {/* <Content1 /> */}
      {/* faq */}
      {/* <FAQ /> */}
      {/* <QuickAccess /> */}
      {/* <Content10 /> */}
      {/* <Content7 />  */}
      {/* <Content9 /> */}
    </div>
  );
}

export default Home;
