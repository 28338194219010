// ServiceCommitment.js
import React from "react";
import "../scss/keyServices.css";

const ServiceCommitment = () => {
  return (
    <div className="service-commitment">
      <h2 className="heading">Service Commitment</h2>
      <div className="content">
        <p>
          MotoDoctor is dedicated to delivering exceptional service with a focus on reliability, speed, and customer
          satisfaction. Our commitment is to provide expert assistance whenever and wherever you need it, ensuring your
          motorcycle is always in safe hands. We prioritize transparency, quality workmanship, and a hassle-free
          experience, aiming to build lasting trust and confidence with every rider we serve.
        </p>
        <p>
          MotoDoctor is committed to maintaining the highest standards of quality in every service we offer. Our team of
          skilled mechanics undergoes rigorous training to ensure consistent, top-notch performance. We use only
          high-quality parts and tools, and follow strict protocols to guarantee safety and reliability. Customer
          satisfaction is at the core of our business, and we strive to exceed expectations by delivering timely,
          transparent, and professional services. Your feedback is invaluable, and we continuously refine our processes
          to meet and surpass your needs.
        </p>
      </div>
    </div>
  );
};

export default ServiceCommitment;
