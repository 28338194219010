import React from "react";
import { Link } from "react-scroll";

const NavItem = ({ menu, setNavActive = () => {} }) => {
  return (
    <div className="dropdown">
      <Link
        activeClass="active"
        smooth
        spy
        to={menu.name}
        className="navItem"
        onClick={() => {
          setNavActive(false);
        }}
      >
        {menu.name}
      </Link>
    </div>
  );
};

export default NavItem;
