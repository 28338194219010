// HowItWorks.js
import React from "react";
import "../scss/howItWorks.css";

const HowItWorks = () => {
  return (
    <section className="how-it-works">
      <h2>How It Works ?</h2>
      <div className="content">
        <iframe
          src="https://www.youtube.com/embed/q5BoifxfKJo"
          title="How It Works Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>

        <p>or</p>
        <a href="/mannual" className="pdf-link">
          Read this step-by-step process 
        </a>
      </div>
    </section>
  );
};

export default HowItWorks;
