import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "./button";
import logo from "../Assets/Logo/Moto-Doc-Logo.svg";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import "../scss/navbar.css";
import NavItem from "./NavItem";

const Header = () => {
  const [navActive, setNavActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const navLinks = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Services", path: "/services" },
    { name: "FAQ'S", path: "/faq" },
    { name: "Contact Us" },
  ];

  const handleNavClick = (menu) => {
    if (menu.path) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate(menu.path);
    } else if (menu.name === "Contact Us") {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    } else {
      const section = document.getElementById(menu.name.toLowerCase());
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
    setNavActive(false);
  };
  return (
    <div className="header">
      <div className="nav">
        <div>
          <a href="/">
            <img
              src={logo}
              className="logo"
              style={{
                height: 80,
                width: 80,
                objectFit: "contain",
                cursor: "pointer",
              }}
              alt="logo"
            />
          </a>
        </div>
        <div onClick={() => setNavActive(!navActive)} className="navMenuBar">
          {navActive ? (
            <CloseOutlined style={{ fontSize: "30px", color: "#000" }} />
          ) : (
            <MenuOutlined style={{ fontSize: "30px", color: "#000" }} />
          )}
        </div>
        <div className={navActive ? "activeMenu navMenuList" : "navMenuList"}>
          {navLinks.map((menu, idx) => (
            <div
              key={idx}
              className={`navItem ${location.pathname === menu.path ? "navItem activeMenu" : ""}`}
              onClick={() => handleNavClick(menu)}
              style={{ cursor: "pointer" }}
            >
              {menu.name}
            </div>
          ))}
          <div className="download">
            <Button text="DOWNLOAD APP" />
            {/* <DropdownMenu /> */}
            <div className="download-content">
              <a href="/rider" target="_blank">
                Rider
              </a>
              <a href="/mechanic" target="_blank">
                Mechanic
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
