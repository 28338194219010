import React from "react";
import Content6 from "./content6";
import PickupDrop from "./pickupDrop";

function Services() {
  return (
    <div>
       <Content6 />
      <PickupDrop /> 
     
    </div>
  );
}

export default Services;
