// HeroSection.js
import React from "react";
import "../scss/hero.css";

const Hero = () => {
  return (
    <section className="hero-section">
      <div className="hero-overlay">
        <h1>MotoDoctor - Your Trusted Motorcycle Rescue Partner</h1>
        <p>24/7 Roadside Assistance, On-Demand Mechanics, and More</p>
        <button className="cta-button" onClick={() => window.open("/rider", "_blank")}>
          Get Help Now
        </button>
      </div>
    </section>
  );
};

export default Hero;
