import React from "react";
import "../scss/pickupDrop.css";
import pickupImage from "../images/Tow and Transport 3.jpg";

const PickupDrop = () => {
  return (
    <section className="pickup-drop">
      <div className="container">
        <h2 className="title">MotoDoctor Pickup and Drop Service</h2>
        <p className="description">
          MotoDoctor’s Pickup and Drop service is designed to make motorcycle maintenance and repairs as convenient as
          possible. Whether your bike needs routine service, repairs after a breakdown, or transport to another
          location, our team will handle everything from start to finish.
        </p>
        <p className="description">
          We pick up your motorcycle from your desired location, transport it securely to our service centre or your
          chosen destination, and drop it back to you once the work is done.
        </p>
        <div className="image-container">
          <img src={pickupImage} alt="Bike being towed" className="service-image" />
        </div>
        <div className="info">
          <div className="booking-options">
            <h3>Booking Options:</h3>
            <p>Easily book the service through our mobile app with options to schedule a pickup at your convenience.</p>
          </div>
          <div className="service-areas">
            <h3>Service Areas:</h3>
            <p>Currently available in Chennai, with plans to expand to more locations soon.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PickupDrop;
