import React, { useState } from "react";
import "../scss/motoDoctorBenefits.css";

const Mission = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const benefits = [
    {
      title: "Mission",
      description:
        "MotoDoctor is committed to providing reliable, fast, and accessible motorcycle roadside assistance and repair services, ensuring riders can stay on the road with confidence and peace of mind.",
    },
    {
      title: "Vision",
      description:
        "To become the leading motorcycle support service provider, known for innovation, customer satisfaction, and a comprehensive network of skilled mechanics and service professionals",
    },
    {
      title: "Values",
      description:
        "MotoDoctor is driven by a dedication to quality, customer-centric service, integrity, and continuous improvement, ensuring safety and convenience for every rider.",
    },
    // {
    //   title: "Anywhere, anytime",
    //   description:
    //     "No matter how far you roam, MotoDoctor is close enough to help. Whether you're on a cross-country road trip or navigating the local streets, our extensive network ensures that help is never too far away. Your safety is our priority, and we're with you every step of the way.",
    // },
  ];

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div style={{ marginTop: 20 }} className="benefits-container">
      <h2 className="benefits-heading">Company Overview</h2>
      <div className="benefits-list">
        {benefits.map((benefit, index) => (
          <div
            key={index}
            className={`benefit-item ${activeIndex === index ? "active" : ""}`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(index)}
          >
            <h3>{benefit.title}</h3>
            <p>{benefit.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Mission;
