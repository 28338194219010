import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"; // Correct import for CSS
import "swiper/css/autoplay"; // Correct import for CSS
import { Navigation, Autoplay } from "swiper/core";

const Content6 = () => {
  const features = [
    {
      title: " Tyre Change ",
      image: "https://s3mediavahanproblemdesc.s3.ap-south-1.amazonaws.com/Tyer_change.svg",
      description: "We offer quick and efficient tyre replacement services to get you back on the road in no time.",
    },
    {
      title: " Flat Tire",
      image: "https://s3mediavahanproblemdesc.s3.ap-south-1.amazonaws.com/Tyer_puncture.svg",
      description: "If you have a punctured or deflated tire, our experts will repair or replace it on the spot.",
    },
    {
      title: "Starting Trouble",
      image: "https://s3mediavahanproblemdesc.s3.ap-south-1.amazonaws.com/Spark_plug_check_and_clean.svg",
      description:
        "Starting Trouble? Our team will diagnose and fix the problem, whether it's a minor adjustment or something more complex.",
    },
    {
      title: "Battery Jumpstart",
      image: "https://s3mediavahanproblemdesc.s3.ap-south-1.amazonaws.com/Battery_check.svg",
      description:
        "We provide jumpstart services for motorcycles with dead or weak batteries, ensuring you can continue your journey",
    },
    {
      title: "Break Down ",
      image: "https://s3mediavahanproblemdesc.s3.ap-south-1.amazonaws.com/Brake_pad_checkchange.svg",
      description:
        "If your motorcycle breaks down, we offer immediate assistance to either repair it on-site or tow it to a service station.",
    },
    {
      title: " Chain Issue ",
      image: "https://s3mediavahanproblemdesc.s3.ap-south-1.amazonaws.com/chain_Change.svg",
      description:
        "Whether your chain is loose, broken, or requires lubrication, we handle all chain-related problems swiftly and effectively.",
    },
    {
      title: " Clutch Cable Issue ",
      image: "https://s3mediavahanproblemdesc.s3.ap-south-1.amazonaws.com/clutch_cable_change.svg",
      description: "We replace and adjust cables to get your bike moving again.",
    },
    {
      title: "Electrical Issue",
      image: "https://s3mediavahanproblemdesc.s3.ap-south-1.amazonaws.com/Spark_plug_check_and_clean.svg",
      description:
        "From faulty wiring to blown fuses, our technicians can diagnose and fix various electrical problems.",
    },
    {
      title: "Accident",
      image: "https://storage.googleapis.com/file-uploads-vahaninc/Accident.svg",
      description:
        "In case of an accident, we provide necessary roadside assistance, including towing and initial damage assessment.",
    },
    {
      title: "Gas",
      image: "https://storage.googleapis.com/file-uploads-vahaninc/Fuel_Lost.svg",
      description: "If you run out of fuel, we deliver it directly to your location so you can continue your ride.",
    },
    {
      title: "Others",
      image: "https://s3mediavahanproblemdesc.s3.ap-south-1.amazonaws.com/Indicator_Change.svg",
      description:
        "MotoDoctor has you covered with a wide range of roadside services to ensure your safety and peace of mind",
    },
  ];

  const renderFeatures = (features, index) => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 20,
          paddingTop: 40,
          position: "relative",
        }}
      >
        <img src={features.image} width={60} height={60} />
        <div
          style={{
            fontSize: 23,
            fontWeight: 800,
          }}
        >
          {features.title}
        </div>
        <div
          style={{
            padding: "0px 30px 70px 30px",
            textAlign: "center",
          }}
        >
          {features.description}
        </div>
        <div
          style={{
            width: 70,
            height: 70,
            backgroundImage: "linear-gradient(180deg, #FF8264 30%, #FF5127 70%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            color: "#fff",
            fontWeight: 700,
            position: "absolute",
            bottom: "-35px",
          }}
        >
          0{index}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        paddingTop: 50,
        paddingBottom: 50,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 25,
        }}
      >
        <div
          style={{
            color: "#000",
            fontWeight: 700,
            textAlign: "center",
          }}
          className="content6Head"
        >
          Emergency Roadside Assistance
        </div>
        <hr
          style={{
            width: "5%",
            border: "1.5px solid #FF8264",
          }}
        />
      </div>
      <div
        style={{
          marginTop: 50,
          width: "90vw",
          display: "flex",
          marginLeft: "auto",
          marginRight: "auto",
          gap: 25,
        }}
      >
        <Swiper
          centeredSlides={false}
          spaceBetween={20}
          modules={[Navigation, Autoplay]} // Correct usage of modules
          loop={true}
          autoplay={{
            delay: 3000, // Delay of 3 seconds
            disableOnInteraction: false, // Continue autoplay even after interactions
          }}
          navigation={true}
          grabCursor={true}
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 20,
              centeredSlides: true,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          style={{
            padding: "20px 20px",
            "--swiper-navigation-color": "#000",
            "--swiper-navigation-size": "18px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {features.map((feature, index) => (
            <SwiperSlide
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 70,
              }}
            >
              <div
                style={{
                  width: 300,
                  height: 250,
                  backgroundColor: "#FAFAFA",
                  borderRadius: 10,
                  border: "0.1px solid #FFE5DE",
                }}
              >
                {renderFeatures(feature, index + 1)}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Content6;
