import React from "react";
import logo from "../Assets/Logo/Moto-Doc-Logo.svg";
const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div>
        <a href="/">
          <img
            src={logo}
            className="logo"
            style={{
              height: 80,
              width: 80,
              objectFit: "contain",
              cursor: "pointer",
            }}
            alt="logo"
          />
        </a>
      </div>
      <h1>Privacy Policy</h1>
      {/* Your content goes here */}
      <p>
        Your privacy matters to Motodoctor Private Limited (the “Company”, “we”, “MotoDoctor”, “us” or “our”). This
        Privacy Policy (“Policy”) describes the policies and procedures on the collection, use, processing, storage,
        retrieval, disclosure, transfer and protection of your information, including personal information and sensitive
        personal data or information (“Information”), that MotoDoctor may receive through your online access,
        interaction or use, of the MotoDoctor mobile applications (“MotoDoctor App”) or our website located at
        https://motodoctor.in/ (the website and MotoDoctor App are collectively referred to as the “MotoDoctor
        Platform”) or through your offline interaction with us including through emails, phones, in person, etc., or
        while availing our Services.
      </p>
      <p>
        The terms “you” and “your” refer to a Mechanic (defined below), a Rider (defined below), a Vendor Partner
        (defined below), or any other user of the MotoDoctor Platform and / or availing the Services (defined below).
        The term “Services” refers to any services offered by MotoDoctor in accordance with the terms and conditions
        applicable to you (and available on the MotoDoctor Platform) whether on the MotoDoctor Platform or otherwise.
        Capital terms not defined herein have the meaning assigned to them in the terms and conditions applicable to you
        and available on the MotoDoctor Platform.
      </p>
      <p>
        Please read this Policy before using the MotoDoctor Platform or submitting any Information to MotoDoctor. This
        Policy is a part of and incorporated within, and is to be read along with, the terms and conditions applicable
        to the users of the MotoDoctor App available on the MotoDoctor Platform.
      </p>
      <h2>USER ACCEPTANCE</h2>
      <p>
        By accessing or using the MotoDoctor Platform or the Services, you agree and consent to this Policy, along with
        any amendments made by the Company at its sole discretion and posted on the MotoDoctor Platform from time to
        time. Any collection, processing, retrieval, transfer, use, storage, disclosure, and protection of your
        Information will be in accordance with this Policy and applicable laws including but not limited to Information
        Technology Act, 2000, and the rules framed thereunder (“Applicable Laws'). If you do not agree with the Policy,
        please do not use or access the MotoDoctor Platform.
      </p>
      <p>
        You hereby represent to MotoDoctor that: The information you provide to MotoDoctor from time to time is and will
        be authentic, correct, current, and updated and you have all the rights, permissions, and consents as may be
        required to provide such Information to MotoDoctor. Your providing of the Information as well as MotoDoctor 's
        consequent storage, collection, usage, transfer, access, or processing of such Information will not be in
        violation of any agreement, Applicable Laws, charter documents, judgments, orders, and decrees. If you disclose
        to us any Information relating to other people, you represent that you have the authority to do so and to permit
        us to use such Information in accordance with this Policy.
      </p>
      <h2>DEFINITIONS</h2>
      <p>
        Unless otherwise provided in this Policy, the terms capitalized in the Policy shall have the meaning as provided
        hereunder: “Mechanics or Partners” means independent third-party service operators or riders who wish to offer
        services on the MotoDoctor Platform to a user/Customer. “Co-branded Services” shall have the meaning assigned to
        the term in paragraph 5(c) hereto. “Customer” shall mean a person who wishes to receive services of the
        Mechanics or Partners on the MotoDoctor Platform. “Device” shall mean computer, mobile, or other device used to
        access the Services. “Device Identifier” shall mean IP address or other unique identifiers of the Device.
        “Promotion” shall mean any contest and other promotions offered by us. “Personal Information” shall mean such
        categories of information that could reasonably be used to identify you personally, including your name, e-mail
        address, and mobile number. “TPSP” shall mean a third-party service provider. “Usage Information” shall have the
        meaning assigned to the term in paragraph 3(II) hereto. “Mechanics or Partners” shall mean third-party providers
        who offer repair services on the MotoDoctor Platform.
      </p>
      <h2>WHAT INFORMATION DO WE COLLECT?</h2>
      <h3>INFORMATION YOU PROVIDE TO US: </h3>
      <p>
        <b>Personal Information:</b> We may ask you to provide certain Personal Information to us. We may collect this
        information through various means and in various places for the provision of Services, including account
        registration forms, contact us forms, or when you otherwise interact with us. When you sign up to use the
        Services, you create a user profile. We shall ask you to provide only such Personal Information which is for
        lawful purposes connected with our Services and necessary to be collected by us for such purpose. The
        information you provide to us includes the following:
      </p>
      <p>
        <b>Account Information:</b> Create or update your MotoDoctor account which may include your email address, name,
        address, mobile number, gender, date of birth, photograph, login name, password, banking or payment related
        information (as permitted by Applicable Laws), etc.
      </p>
      <p>
        <b> Saved Information:</b> While you use our Services, we may collect and store Information about you to process
        your requests and automatically complete forms for future transactions, including (but not limited to) your
        phone number, address, email address, billing information, emergency contact information, etc.
      </p>
      <p>
        <b>Verification Information:</b> If you are a Mechanics or Partner, we may collect location details, profile
        picture, call and SMS details, copies of government-issued identification documents such as Aadhaar, Permanent
        Account Number, etc., license details, and other details (KYC), Mechanic shop or service centre-related
        documents such as certificate of registration, insurance etc., user settings, and such other documents which
        evidence the health or fitness of the vehicle to provide Services on the MotoDoctor Platform from time to time.
        If you are a Mechanics or Partner we may also require you to capture your real-time self-clicked images
        (selfies) and upload such selfies on the MotoDoctor Platform from time to time to verify your identity.
      </p>
      <p>
        <b> Background check Information:</b> We collect background check and identity verification information of the
        Mechanics or Partners. This may include a collection of Information such as Mechanic's or Partners' history or
        criminal records (where permitted by law), and right to work. This information may also be collected by TPSP on
        the Company’s behalf. Other Apps: In case you sign up as a Mechanics or Partners, enable features that require
        MotoDoctor ’s access to other applications on your Device aiming to prevent and detect fraud towards the
        Customers.
      </p>
      <p>
        <b>Other Information:</b> We collect additional Information you provide when you correspond with MotoDoctor for
        customer support or report problems for troubleshooting. We also collect Information that you may submit
        electronically such as when you use in-app messaging, post on any message boards, or provide ratings, reviews,
        or comments. In case you refer a friend, we may also collect, store, and use the name and contact information of
        your friend to promote our Services.
      </p>
      <p>
        <strong>
          INFORMATION WE COLLECT AS YOU ACCESS AND USE MOTODOCTOR APPS (MOTODOCTOR - RIDER &amp; MOTODOCTOR - MECHANIC)
        </strong>
      </p>
      <p>
        <b>Transaction Information:</b> We collect transaction Information such as Repair details, Rider location, and
        addresses, distance travelled, payment transaction information (subject to Applicable Laws), etc.
      </p>
      <p>
        <b>Location data: Mechanics or Partners:</b> MotoDoctor collects location data when the MotoDoctor - Mechanic
        App is running in the foreground (the app is open and on-screen) or background (the app is not in use) on your
        mobile device. For Mechanics or Partners, MotoDoctor collects location data only when the Mechanics or Partners
        have logged in on the MotoDoctor App.
      </p>
      <p>
        <b>Customers:</b> We collect precise or approximate location data from your Device if you enable us to do so.
        MotoDoctor - Rider App collects this data from the time a Service is requested until it is finished, and any
        time the app is running in the foreground of your Device. We use this data to enhance your use of the MotoDoctor
        App, including to improve Service locations, enable safety features, and prevent and detect fraud. Even if you
        have not enabled us to collect location data from your Device, MotoDoctor collects the Mechanics' or
        Partners&rsquo; location data collected during a Service, and links such location data with your account. This
        enables us to offer services to you, such as receipt generation and customer support, and enable the safety of
        the Customers.
      </p>
      <p>
        <b>Usage Information:</b> We, our TPSP, and the Mechanics' or Partners&rsquo; may use a variety of technologies
        that automatically (or passively) collect certain information whenever you visit or interact with the MotoDoctor
        Platform for obtaining the Services (&ldquo;Usage Information&rdquo;). This Usage Information may include the
        browser that you are using, the URL that referred you to our Services, all of the areas within our Services that
        you visit, and the time of day, searches and search results, or usage behaviour on the MotoDoctor App, etc.
      </p>
      <p>
        <b>Health-related Information:</b> If you are a Mechanic or Partner, we may collect certain health-related
        Information and declarations such as body temperature, symptoms, vaccination status, and other health
        conditions.
      </p>
      <p>
        <b>Device Information:</b> We collect information by ourselves or through integration with third-party
        applications which consist of technical information and aggregated usage information, and may contain, among
        other things, the Device Identifier of your Device, your preferred language and country site, manufacturer,
        software, and model of your Device, Device type, operating systems and versions, your geolocation, mobile
        network data, screens you have visited, your touch gestures performed in your MotoDoctor App, your scrolling
        activity, and any other actions you have performed during your use of MotoDoctor App, etc., to enhance the user
        interface and experience on MotoDoctor Platform, facilitate the provision of software updates, product support
        and other services to you, etc. Any sensitive information about other programs that you are running on your
        Device, passwords, and activity across other applications are not collected and all the sensitive information is
        masked.
      </p>
      <p>
        <b>SMS/Text Messages:</b> We may collect data from SMS/ text messages upon receiving Device access permissions
        from you for the purposes of (i) issuing and receiving one-time passwords and other device verification, and
        (ii) automatically filling verification details during financial transactions, either through us or a TPSP, in
        accordance with Applicable Laws. We do not share or transfer SMS/ text message data to any third party other
        than as provided under this Policy.
      </p>
      <p>
        <b>Call details:</b>If you are a Mechanics' or Partners&rsquo;, we will, additionally, record your calls with us
        made from the Device used to provide Services and related call details. Other Information: We collect
        Information about how you interact with the MotoDoctor App and any of our websites to which the MotoDoctor App
        links, such as how many times you use a specific part of the MotoDoctor App over a given time period, the amount
        of time you spend using the MotoDoctor App, how often you use the MotoDoctor App, actions you take in the
        MotoDoctor App and how you engage with the MotoDoctor App, etc.
      </p>
      <p>
        <b>Cookies:</b> Usage Information may be collected using a cookie. If you do not want information to be
        collected through the use of cookies, your browser/app settings allow you to deny or accept the use of cookies.
        Cookies can be disabled or controlled by setting a preference within your web browser or on your Device. If you
        choose to disable cookies or flash cookies on your Device, some features of the Services may not function
        properly or we may not be able to customize the delivery of information to you. The Company cannot control the
        use of cookies (or the resulting information) by third parties, and the use of third-party cookies is not
        covered by our Policy
      </p>
      <p>
        <strong>INFORMATION THIRD PARTIES PROVIDE ABOUT YOU</strong>
      </p>
      <p>
        We may, from time to time, collect Information about you through the MotoDoctor Platform or while availing the
        Services and collect Information from our affiliates or third parties / TPSPs such as technical sub-contractors,
        business partners, analytics providers, search information providers, payment service providers, etc., and also
        from publicly available sources such as commercially available marketing lists, social networks, and other
        related media.
      </p>
      <p>
        <strong>USE OF INFORMATION COLLECTED</strong>
      </p>
      <p>
        Our primary goal in collecting your Information is to provide you with an enhanced experience when using the
        Services. We may use your Information we collect in accordance with this Policy for the following purposes:
      </p>
      <ul>
        <li>To enable you to access the MotoDoctor Platform</li>
        <li>
          To verify your identity and/ or your capacity, under applicable law, to provide Services through the
          MotoDoctor Platform
        </li>
        <li>To closely monitor which features of the Services are used most</li>
        <li>To allow you to view your service history, rate of service or bookings</li>
        <li>
          To determine what features need to be improved for enhanced user experience - including usage patterns and
          geographic locations to determine where we should offer or focus services, features and/or resources
        </li>
        <li>To send you a welcome email/SMS to verify your username and password</li>
        <li>
          To provide you with the correct app version depending on your Device type, for troubleshooting and in some
          cases, marketing purposes
        </li>
        <li>To help diagnose problems with our computer server</li>
        <li>To administer the MotoDoctor Platform</li>
        <li>
          To send you strictly service-related announcements on rare occasions when it is necessary to do so. For
          instance, if our Services are temporarily suspended for maintenance, we might send you an email. If you do not
          wish to receive them, you have the option to deactivate your account
        </li>
        <li>
          To prevent, discover, and investigate violations of this Policy or any applicable terms of service or terms of
          use
        </li>
        <li>
          To identify and/or detect security breaches or attacks, errors, fraud, money laundering, abuse, and other
          criminal activities, and investigating and taking appropriate remedial action.
        </li>
      </ul>
      <p>
        We provide some of your Personal Information (such as your name, Service location or address, and contact
        number) to the Mechanics' or Partners who accepts your request for Services so that the Mechanics' or Partners
        may contact and find you.
      </p>
      <p>
        If you are a Rider or Customer, we use geo-location information for various purposes, including for you to be
        able to view the Mechanics' or Partners in your area that are close to your location to provide services to you,
        to automatically fetch your location when you open the MotoDoctor App, for the Mechanics' or Partners&rsquo; to
        identify the Service location and to allow you (if you choose through any features we may provide) to share this
        information with other people for your safety.
      </p>
      <p>
        If you are a Mechanics' or Partners&rsquo;, we use the geo-location information for (only when the Mechanics' or
        Partners the logged-in on the MotoDoctor App) you to receive orders close to your location even when the
        MotoDoctor App is not in use or closed, to enable the Customers or Rider to track the distance of the Mechanics
        or Partners from their location for Service, to share the exact location for the safety of the Customers, etc.
      </p>
      <p>
        If you are a Mechanic or Partner, we may share your name, vehicle number, driving license number, phone number
        and/or profile picture (if applicable), tracking details with our Customers to provide them the Services; We may
        use your Personal Information or Usage Information that we collect about you:
      </p>
      <ul>
        <li>
          To provide you with information or services or process transactions that you have requested or agreed to
          receive including to send you electronic newsletters, or to provide you with special offers or promotional
          materials on behalf of us or third parties
        </li>
        <li>
          To enable you to participate in a variety of the Services&rsquo; features such as online or mobile entry
          sweepstakes, contests or other promotions
        </li>
        <li>
          To contact you with regard to your use of the Services and, in our discretion, changes to the Services and/or
          the Services&rsquo; policies
        </li>
        <li>For internal business purposes</li>
        <li>For inclusion in our data analytics</li>
        <li>
          For purposes disclosed at the time you provide your Information or as otherwise set forth in this Policy.
        </li>
        <li>
          To enhance your user experience in relation to the MotoDoctor App or the Services, including customisation /
          personalization of the MotoDoctor App or the Services
        </li>
        <li>To provide relevant offers or rewards to you, based on your consumption patterns</li>
        <li>To enforce our terms and conditions and this Policy, and resolve any disputes</li>
        <li>
          To provide functionality, analyse performance, fix errors, bugs, and improve the usability and effectiveness
          of the MotoDoctor Platform
        </li>
        <li>
          To comply with Applicable Laws or requests received from regulators, government, law enforcement or judicial
          authorities under Applicable Laws or our contract with a third party
        </li>
        <li>To carry out our obligations and enforcing rights arising from any contracts between us</li>
        <li>
          To disclose to affiliates, our and their employees, agents and representatives on a need-to-know basis to
          facilitate provision of Services
        </li>
        <li>
          To deliver any administrative notices, alerts, advice, notifications and communication relevant to your use of
          the Services, through social media (including WhatsApp), SMS and other media; If you sign up to use our
          Services as an employee or as a stakeholder of a third party with whom the Company has an arrangement and has
          offered discount coupons/ or extended certain promotional offers, the Company may share any information
          provided by you with such third party to be utilized by them for limited internal business purposes only
        </li>
        <li>
          To fulfil any other purpose for which you provide us the Information and/or for any other purpose with your
          consent. Please note, we do not use the information collected from you for targeted advertising.
        </li>
      </ul>
      <p>
        <strong>HOW AND WHEN DO WE DISCLOSE INFORMATION TO THIRD PARTIES</strong>
      </p>
      <p>
        We do not sell, share, rent or trade the information we have collected about you, other than as disclosed within
        this Policy or at the time you provide your Information. Following are the situations when Information may be
        shared:
      </p>
      <p>
        <strong>WHEN YOU AGREE TO SHARE INFORMATION WITH THIRD PARTIES:</strong>
      </p>
      <p>
        You may opt to receive information and/or marketing offers directly from third parties when you access third
        party links on the MotoDoctor App.
      </p>
      <p>
        If you do agree to have your Personal Information shared, your Personal Information will be disclosed to such
        third parties and all Information you disclose will be subject to the privacy policy and practices of such third
        parties. We are not responsible for the privacy policies and practices of such third parties and, therefore, you
        should review the privacy policies and practices of such third parties prior to agreeing to receive such
        information from them.
      </p>
      <p>
        If you later decide that you no longer want to receive communication from a third party, you will need to
        contact that third party directly.
      </p>
      <p>
        <strong>THIRD PARTIES PROVIDING SERVICES ON OUR BEHALF</strong>
      </p>
      <p>
        We may share the Information you provide with our TPSPs, business partners, and agents. Please refer to such
        third-party&rsquo;s privacy policy for more details before using their services on the MotoDoctor App.
      </p>
      <p>
        We use TPSPs to facilitate our Services, provide or perform certain aspects of the Services on our behalf
        &ndash; such as host the Services, design and/or operate the Services&rsquo; features, track the Services&rsquo;
        analytics, process payments, engage in anti-fraud and security measures, perform background and identity
        verification, run criminal record checks, provide customer support, provide geo-location information to
        Mechanics' or Partners&rsquo;, enable us to send you special offers, host our job application form, perform
        technical services (e.g., without limitation, maintenance services, database management, web analytics and
        improvement of the Services&lsquo; features), or perform other administrative services. These third parties will
        have access to Information, including Personal Information to only carry out the services they are performing
        for you or for us. We will require each of these TPSPs to ensure the same level of data protection as us and
        impose contractual obligations not to disclose or use Personal Information for any other purpose. TPSPs
        providing analytics-related services may set and access their own cookies, web beacons and embedded scripts on
        your Device and they may otherwise collect or have access to Information about you. We use a third-party hosting
        provider who hosts our support section of our website. Information collected within this section of our website
        by such TPSP is governed by our Policy.
      </p>
      <p>
        <strong>CO-BRANDED SERVICES</strong>
      </p>
      <p>
        Certain aspects of the Services may be provided to you in association with third parties (&ldquo;Co-Branded
        Services&rdquo;) such as credit houses, loan providers, sponsors and charities, and may require you to disclose
        Information including Personal Information to them. Such Co-Branded Services will identify the third party. If
        you elect to register for products and/or services through the Co-Branded Services, you shall have deemed to
        have consented to providing your Information to both us and the third party. Further, if you sign-in to a
        Co-Branded Service with a username and password obtained through our Services, your Personal Information may be
        disclosed to the identified third parties for that Co-Branded Service and will be subject to their privacy
        policies and practices.
      </p>
      <p>
        <strong>CONTESTS AND PROMOTIONS</strong>
      </p>
      <p>
        We may offer Promotions through the Services that may require registration. By participating in a Promotion, you
        are agreeing to the official rules that govern that Promotion, which may contain specific requirements of you,
        including, allowing the sponsor of the Promotion to use your name, voice and/or likeness in advertising or
        marketing associated with the Promotion. If you choose to enter a Promotion, you agree that your Personal
        Information may be disclosed to third parties or the public in connection with the administration of such
        Promotion, including, in connection with winner selection, prize fulfilment, and as required by law or permitted
        by the Promotion&rsquo;s official rules, such as on a winners list.
      </p>
      <p>
        <strong>ADMINISTRATIVE AND LEGAL REASONS</strong>
      </p>
      <p>
        We cooperate with Government and law enforcement officials and private parties to enforce and comply with the
        Applicable Laws. Thus, we may access, use, preserve, transfer and disclose your information (including Personal
        Information, IP address, Device Information or geo-location data), to government or law enforcement officials or
        private parties as we reasonably determine is necessary and appropriate:
      </p>
      <ul>
        <li>to satisfy any Applicable Law, regulation, subpoenas, Governmental requests or legal process</li>
        <li>
          to protect and/or defend the terms and conditions applicable to use of the MotoDoctor App or the Services,
          including investigation of potential violations thereof
        </li>
        <li>to protect the safety, rights, property or security of the Company, our Services or any third party</li>
        <li>to protect the safety of the public for any reason;</li>
        <li>to detect, prevent or otherwise address fraud, security or technical issues</li>
        <li>
          to prevent or stop activity we may consider to be, or to pose a risk of being, an illegal, unethical, or
          legally actionable activity.
        </li>
      </ul>
      <p>
        <strong>AFFILIATES AND BUSINESS TRANSFER</strong>
      </p>
      <p>
        We may share your Information, including your Personal Information and Usage Information with our parents,
        subsidiaries and affiliates for internal reasons, including business and operational purposes. We also reserve
        the right to disclose and transfer all such information:
      </p>
      <ul>
        <li>to a subsequent owner, co-owner or operator of the Services or applicable database</li>
        <li>
          in connection with a corporate merger, consolidation, restructuring, the sale of substantially all of our
          membership interests and/or assets or other corporate change, including, during the course of any due
          diligence process.
        </li>
      </ul>
      <p>
        <strong>MARKET STUDY AND OTHER BENEFITS</strong>
      </p>
      <p>
        We may share your information, including your Personal Information and Usage Information with third parties for
        any purpose, including but not limited to undertaking market research/ study, conduct data analysis, determine
        and customize product or service offerings, to improve the products or Services or to make any other
        benefits/products/ services available to you.
      </p>
      <p>
        <strong>THIRD-PARTY CONTENT AND LINKS TO THIRD-PARTY SERVICES</strong>
      </p>
      <p>
        The Services may contain content that is supplied by a third party, and those third parties may collect website
        usage information and your Device Identifier when web pages from any online or mobile Services are served to
        your browser. In addition, when you are using the Services, you may be directed to other sites or applications
        that are operated and controlled by third parties that we do not control, in which case our Policy will no
        longer apply. We are not responsible for the privacy practices employed by any of these third parties. For
        example, if you click on a banner advertisement, the click may take you away from the MotoDoctor App onto a
        different website. These other websites may send their own cookies to you, independently collect data or solicit
        Information, and may or may not have their own published privacy policies. Information (including Personal
        Information) may be collected by third parties if there is content from the MotoDoctor App that you specifically
        and knowingly upload to, share with or transmit to an email recipient, online community, website, or to the
        public, e.g. uploaded photos, posted reviews or comments, or information about you or your ride or booking that
        you choose to share with others through features which may be provided on our Services. This uploaded, shared,
        or transmitted content will also be subject to the privacy policy of the email, online community website, social
        media or other platform to which you upload, share or transmit the content. We are not responsible for the
        privacy practices employed by any of these third parties. Our online and mobile Services may include social
        media features, such as the Facebook Like button, and widgets such as a &ldquo;Share This&rdquo; button, or
        interactive mini-programs that run on the MotoDoctor App. These features may collect information including your
        IP address, and photograph, which page you are visiting on our online or mobile Services, and may set a cookie
        to enable the feature to function properly. Social media features and widgets are either hosted by a third party
        or hosted directly on our online Services and/or MotoDoctor Platform. Your interactions with these features and
        widgets are governed by the privacy policy of the company providing them and we will not be responsible or
        liable for any acts or omissions of such third parties. In particular, remember that certain third parties may
        be located in or have facilities that are located in a different jurisdiction, hence, if you elect to proceed
        with a transaction that involves the services of a third-party service provider, then your information may
        become subject to the laws of the jurisdiction(s) in which such service provider is, or its facilities are
        located. We encourage you to note when you leave web pages or links controlled by MotoDoctor App/ Services and
        to read the privacy statements of all third-party websites or applications before submitting any Information to
        such third parties. We will not be liable for any acts or omissions of the third-party service providers.
      </p>
      <p>
        <strong>INFORMATION COLLECTED BY MECHANICS AND PARTNERS</strong>
      </p>
      <p>
        This Policy does not cover the usage of any information about you which is obtained by the Mechanics or Partners
        while providing you Transportation, Delivery, Package or rental services, or otherwise.
      </p>
      <p>
        <strong>CHANGE OF INFORMATION AND CANCELLATION OF ACCOUNT</strong>
      </p>
      <p>
        You are responsible for maintaining the accuracy of the Information you submit to us, such as your contact
        information provided as part of account registration. If your Personal Information or Information you provide to
        us changes, or if you no longer desire our Services, you may correct, delete inaccuracies, or amend information
        by making the change on our member information page or by contacting us through the email address or Mobile
        number mentioned on MotoDoctor Platform or contacting the Grievance Officer. We will make good faith efforts to
        make requested changes in our then active databases as soon as reasonably practicable. You may also cancel or
        modify the communications that you have elected to receive from MotoDoctor by following the instructions
        contained within an e-mail or by logging into your user account and changing your communication preferences. If
        upon modifying or changing the Information earlier provided to Us, we find it difficult to provide access to our
        Services to you due to insufficiency/ inaccuracy of the Information, we may, in our sole discretion terminate
        your access to the Services by providing you a written notice to this effect on your registered email address.
        If you wish to cancel your account or request that we no longer use your information to provide you services,
        contact us through the email address mentioned on the Service bill received, the MotoDoctor Platform or the
        Grievance Officer mentioned in this Policy. Please note, that we may not be able to provide some or all of the
        Services in case you disable access to any of your Information as described under this Policy. We will retain
        your Information including Personal Information and Usage Information (including geo-location) for as long as
        your account with the Services is active and as needed to provide you services. Even after your account is
        terminated, we will retain some of your Information including Personal Information and Usage Information
        (including geo-location, trip history, and transaction history) for such statutory time-period as needed to
        comply with Applicable Laws, to resolve disputes, conclude any activities related to the cancellation of an
        account, investigate, or prevent fraud and other inappropriate activity related to your account, to enforce our
        agreements, or for other business reasons, etc. After completion of such statutory period, your data may either
        be deleted from our database or be anonymized and aggregated, and then may be held by us as long as necessary
        for us to provide our Services effectively, but our use of the anonymized data will be solely for analytic
        purposes.
      </p>
      <p>
        <strong>SECURITY</strong>
      </p>
      <p>
        The information we collect is securely stored within our databases, and we use standard, industry-wide,
        commercially reasonable security practices such as encryption, firewalls, and SSL (Secure Socket Layers) for
        protecting your Information. However, as effective as encryption technology is, no security system is
        impenetrable. We cannot guarantee the security of our databases, nor can we guarantee that the information you
        supply won't be intercepted while being transmitted to us over the Internet or wireless communication, and any
        Information you transmit to us, you do at your own risk. We recommend that you not disclose your password to
        anyone.
      </p>
      <p>
        <strong>INFORMATION OF CHILDREN</strong>
      </p>
      <p>
        We do not knowingly solicit or collect Information from children under the age of 18 years. Use of the
        MotoDoctor App is only available for persons who can enter into a legally binding contract under Applicable Laws
      </p>
      <p>
        <strong>GRIEVANCE OFFICER</strong>
      </p>
      <p>
        If you would like to ask about, make a request relating to, or complain about how We process your information,
        please contact or email our grievance officer, at one of the addresses below. Our grievance officer will attempt
        to expeditiously redress your grievances. For any request, complaint, feedback or grievances, please contact: -
        Email details &ndash; <a href="mailto:support@motodoctor.in">support@motodoctor.in</a>
      </p>
      <p>
        <strong>CHANGES TO THE PRIVACY POLICY</strong>
      </p>
      <p>
        We reserve the right to update/modify, from time to time, this Policy to reflect changes to our Information
        practices. Any changes will be effective immediately upon the posting of the revised Policy on the MotoDoctor
        Platform. If we make any material changes, we will notify you by email or mobile number (sent to the e-mail
        address or mobile number specified in your account) or by means of a notice on the MotoDoctor App prior to the
        change becoming effective. We encourage you to periodically review this page for the latest information on our
        privacy practices. Your use of the MotoDoctor App or availing the Services after an updated Policy becomes
        effective will indicate your acceptance of the updated Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
