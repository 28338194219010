import React, { useState, useEffect, useRef } from "react";
import "../scss/testimonials.css";

const TestimonialSlider = () => {
  const testimonials = [
    {
      id: 1,
      title: "Quick and Reliable Service!",
      text: "I was stranded on the highway with a flat tire, and MotoDoctor came to my rescue within 30 minutes. The mechanic was professional and had me back on the road in no time. Highly recommend their prompt and efficient service!",
    },
    {
      id: 2,
      title: "Convenient and Hassle-Free",
      text: "Booked a pickup and drop service for my bike's maintenance. The whole process was smooth, and I didn't have to worry about anything. MotoDoctor's team handled everything perfectly, and my bike is running great!",
    },
    {
      id: 3,
      title: "Great Customer Support",
      text: "Had an issue with starting my bike and called MotoDoctor. The support team was very responsive and guided me through the process. The mechanic arrived quickly and fixed the problem efficiently. Excellent service and customer care!",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null);

  const startSlider = () => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);
  };

  useEffect(() => {
    startSlider();

    return () => clearInterval(intervalRef.current);
  }, [testimonials.length]);

  const handleDotClick = (index) => {
    clearInterval(intervalRef.current); // Stop the automatic sliding
    setCurrentIndex(index); // Manually set the current index

    setTimeout(() => {
      startSlider(); // Restart the automatic sliding after a delay
    }, 5000); // Delay before restarting the slider
  };

  return (
    <div className="testimonial-slider-container">
      <h2 className="slider-heading">What Our Customers Say ?</h2>
      <div className="testimonial-slide">
        <h3 className="testimonial-title">{testimonials[currentIndex].title}</h3>
        <p className="testimonial-text">{testimonials[currentIndex].text}</p>
      </div>
      <div className="dots-container">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default TestimonialSlider;
