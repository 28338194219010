import React from "react";
import logo from "../Assets/Logo/MD_EU_LOGO_ORANGE (2).svg";
import playStore from "../Assets/Playstore_and_App_Store_Icon/playstore.png";
import applestore from "../Assets/Playstore_and_App_Store_Icon/applestore.png";
import mail from "../Assets/Footer Icons/envelope.svg";
import phone from "../Assets/Footer Icons/phon.svg";

const RiderApp = () => {
  const handleAppleStoreClick = () => {
    alert("App Store version coming soon!");
  };
  return (
    <div className="contain orange-background">
      <div className="center-box box-padding">
        <div>
          <a style={{ fontWeight: "bold", marginTop: "20px" }} href="https://www.motodoctor.in/">
            MOTODOCTOR.IN
          </a>
        </div>
        <img src={logo} alt="Moto Doc Logo" className="centered-image" />
        <p style={{ fontWeight: "500", marginTop: "20px" }}>MOTODOCTOR RIDER</p>
        <div className="app-icons">
          <a href="https://play.google.com/store/apps/details?id=com.vahaninc.autodoctor" target="_blank">
            <img src={playStore} className="app-store-icon" alt="Play Store" />
          </a>
        </div>
        <div className="app-icons">
          <a href="https://apps.apple.com/in/app/motodoctor-rider/id6478849754" target="_blank">
            <img src={applestore} className="app-store-icon" alt="Apple Store" />
          </a>
        </div>
        <p class="footer-call-to-action-link-wrapper">
          <a class="footer-call-to-action-link">9500900620</a>
          <img src={phone} alt="Mail Icon" class="mail-icon" />
        </p>
        <p class="footer-call-to-action-link-wrapper">
          <a class="footer-call-to-action-link" href="mailto:support@motodoctor.in" target="_self">
            support@motodoctor.in
          </a>
          <img src={mail} alt="Mail Icon" class="mail-icon" />
        </p>
      </div>
    </div>
  );
};

export default RiderApp;
