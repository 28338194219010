import React from "react";
import "../scss/faq.css";

const FAQ = () => {
  const faqs = [
    {
      question: "What services does MotoDoctor offer?",
      answer:
        "MotoDoctor provides 24/7 roadside assistance, on-demand mechanics, pickup and drop services, towing and transport, emergency fuel delivery, and solutions for common motorcycle issues such as flat tires, battery jumpstart, and more.",
    },
    {
      question: "How do I request assistance?",
      answer:
        "You can request assistance through our mobile app. Simply choose the service you need, provide your location, and your MotoDoctor will be dispatched to assist you.",
    },
    {
      question: "What areas does MotoDoctor cover?",
      answer:
        "MotoDoctor currently operates in Chennai, Tamil Nadu, with plans to expand to additional locations. You can check the specific service areas on our mobile app.",
    },
    {
      question: "How long does it take for assistance to arrive?",
      answer:
        "Response times can vary based on your location and the type of service required, but we aim to reach you within 30-45 minutes for emergency services.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "We accept a variety of payment methods including cash payment, credit/debit cards, UPI payment, online banking through our secure payment gateway.",
    },
    {
      question: "Can I schedule a service in advance?",
      answer:
        "Yes, you can schedule pickup, drop, and on-demand mechanic services in advance by selecting your preferred date and time during the booking process on the mobile app.",
    },
    {
      question: "What if my motorcycle needs to be towed?",
      answer:
        "If your motorcycle cannot be repaired on-site, we offer towing and transport services to take it to a repair shop or your preferred location.",
    },
    {
      question: "How can I track the status of my service request?",
      answer:
        "You can track the status of your service request in real-time through our mobile app, where you’ll receive updates on the mechanic’s location and estimated arrival time.",
    },
    {
      question: "What should I do if I have an issue with the service?",
      answer:
        "Customer satisfaction is our priority. If you experience any issues, please contact our customer support team, and we will resolve it promptly.",
    },
    {
      question: "How do I provide feedback about the service?",
      answer:
        "After your service is completed, you’ll receive an option to rate your experience and provide feedback through our app. Your input helps us improve our services.",
    },
    {
      question: "What if I need to cancel or reschedule my service?",
      answer:
        "You can cancel your service through our app or by contacting customer support. Please do so as early as possible. We do not have the facility to reschedule a service for now, but will be coming in the future.",
    },
    {
      question: "How can I contact MotoDoctor for more information?",
      answer:
        "You can reach out to us through our contact form on the website, by phone, or via our social media channels. We are here to assist you with any inquiries.",
    },
  ];

  return (
    <div  style={{ marginTop: 40 }}className="faq-container">
      <h2 className="faq-heading">Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <h3 className="faq-question">{faq.question}</h3>
            <p className="faq-answer">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
