import React from "react";
import Mission from "./benifits";
import ServiceCommitment from "./keyServices";
function  About() {
  return (
    <div>
      <Mission />
      <ServiceCommitment />
    </div>
  );
}

export default About ;
