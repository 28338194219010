// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/about";
import Services from "./components/service";
import FAQ from "./components/faq";
import Footer from "./components/Footer";
import "./scss/styles.css";
import PrivacyPolicy from "./components/privacyPolicy"; // Make sure the component name matches the file name
import TermsCondition from "./components/termsCondition";
import RiderApp from "./components/riderApp";
import MechanicApp from "./components/mechanicApp";
import Content10 from "./components/content10";

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  // Check if the current route is "/privacy-policy"
  const isPrivacyPolicyRoute = location.pathname === "/privacy-policy";
  const isTermsCondition = location.pathname === "/terms-condition";
  const isRider = location.pathname === "/rider";
  const isMechanic = location.pathname === "/mechanic";

  return (
    <div
      className="App"
      style={{
        overflow: "hidden",
      }}
    >
      {/* Render the Header only if not in the "privacy-policy" route */}
      {!isPrivacyPolicyRoute && !isTermsCondition && !isRider && !isMechanic && <Header />}

      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/mannual" element={<Content10 />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/rider" element={<RiderApp />} />
          <Route path="/mechanic" element={<MechanicApp />} />
        </Routes>
      </main>

      {/* Render the Footer only if not in the "privacy-policy" route */}
      {!isPrivacyPolicyRoute && !isTermsCondition && !isRider && !isMechanic && <Footer />}
    </div>
  );
}

export default App;
