import React from "react";
// button component

function Button({ text = "" }) {
  return (
    <button
      style={{
        padding: "10px 30px",
        fontSize: 16,
        borderRadius: 35,
        backgroundColor: "#FF8264",
        color: "#fff",
        border: "none",
        width: "fit-content",
        cursor: "pointer",
      }}
    >
      {text}
    </button>
  );
}

export default Button;
