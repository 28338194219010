import React from "react";
import mobile from "../images/rider-home-Photoroom.png";
import Icon1 from "../Assets/Icons/24-7 (1).svg";
import Icon2 from "../Assets/Icons/Mechanic.svg";
import Icon3 from "../Assets/Icons/Layer_4.svg";
import Icon4 from "../Assets/Icons/Fuel.svg";
import Icon5 from "../Assets/Footer Icons/g2507.svg";
const Content5 = () => {
  const details = [
    {
      img: Icon1,
      title: "24/7 Roadside Assistance",
      desc: "MotoDoctor offers round-the-clock roadside support for motorcycle riders. Whether you experience a flat tire, battery issues, or need fuel delivery, our team is ready to help anytime, anywhere.",
    },
    {
      img: Icon2,
      title: "On-Demand Mechanics:",
      desc: "Get access to expert motorcycle mechanics at your convenience. Our on-demand service brings professional repairs and maintenance directly to your location, ensuring minimal disruption to your day.",
    },
    {
      img: Icon3,
      title: "Tow and Transport:",
      desc: "We provide reliable towing and transport services for motorcycles, ensuring safe and secure delivery to your preferred location or repair shop.",
    },
    // {
    //   img: Icon4,
    //   title: "Emergency Fuel Delivery:",
    //   desc: "Ran out of gas? No worries! MotoDoctor(Rider) ensures you never get stranded with our emergency fuel delivery service.",
    // },
    // {
    //   img: Icon5,
    //   title: "Real-Time Tracking:",
    //   desc: "Track the location and estimated arrival time of our mechanics or assistance vehicles in real-time through the app.",
    // },
  ];

  return (
    <section>
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#fff",
          paddingTop: 45,
        }}
      >
        <div
          style={{
            width: "90%",
            height: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
          }}
          className="content5"
        >
          <div
            style={{
              height: "100%",
            }}
            className="content5Img"
          >
            <img
              src={mobile}
              style={{
                width: "100%",
                height: "70%",
                objectFit: "contain",
                marginTop: 30,
              }}
            />
          </div>
          <div className="content5Right">
            <div
              style={{
                fontWeight: 500,
              }}
              className="content5Head"
            >
              Our Key Services{" "}
            </div>
            {/* <div
              style={{
                paddingTop: 30,
              }}
            >
              Are you a motorcycle enthusiast who loves the open road? We understand that unexpected breakdowns or
              maintenance issues can put a dent in your ride. That's why we're thrilled to introduce MotoDoctor, your
              go-to solution for reliable moto mechanic support and roadside assistance.
            </div> */}
            <hr
              style={{
                marginTop: 30,
                width: "10%",
                border: "1.5px solid #FF8264",
              }}
            />
            <div
              style={{
                paddingTop: 50,
                display: "flex",
                flexDirection: "column",
                gap: 60,
                height: "40%",
                // overflowY: "auto",
              }}
            >
              {details.map((detail, idx) => {
                return (
                  <div style={{ display: "flex" }} key={idx}>
                    <img
                      width={80}
                      height={60}
                      src={detail.img}
                      style={{
                        objectFit: "contain",
                      }}
                    />
                    <div
                      style={{
                        paddingLeft: 10,
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 800,
                        }}
                        className="content5DetailHead"
                      >
                        {detail.title}
                      </div>
                      <div
                        style={{
                          width: "95%",
                          paddingTop: 3,
                        }}
                      >
                        {detail.desc}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content5;
